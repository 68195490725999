import React, { useState, useEffect } from 'react';
import './App.css';
import preSelectedMovies from './movies.json'; // Import the pre-selected movie data

const API_URL = 'https://www.omdbapi.com/?apikey=9e17ff';

const App = () => {
  const [query, setQuery] = useState('');
  const [movies, setMovies] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Fetch movie data from OMDb API
  const searchMovies = async (searchTerm) => {
    const response = await fetch(`${API_URL}&s=${searchTerm}`);
    const data = await response.json();
    if (data.Search) {
      setMovies(data.Search);
    }
  };

  // Fetch detailed movie data by IMDb ID
  const fetchMovieDetails = async (movieId) => {
    const response = await fetch(`${API_URL}&i=${movieId}`);
    const data = await response.json();
    setSelectedMovie(data);
  };

  useEffect(() => {
    if (query.length > 2) {
      searchMovies(query);
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  }, [query]);

  const handleSelectMovie = (movie) => {
    fetchMovieDetails(movie.imdbID);
    setDropdownVisible(false);
  };

  const handlePreSelectMovie = (movieId) => {
    fetchMovieDetails(movieId);
  };

  const handleBack = () => {
    setSelectedMovie(null);
  };

  return (
    <div className="app">
      {/* Navbar */}
      <nav className="navbar">
      <a href="/" className="home-link" onClick={handleBack}><div className="logo">movies.halsen.dev</div></a>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search for a movie..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onFocus={() => setDropdownVisible(true)}
          />
          {dropdownVisible && (
            <div className="dropdown">
              {movies.slice(0, 5).map((movie) => (
                <div key={movie.imdbID} className="dropdown-item" onClick={() => handleSelectMovie(movie)}>
                  <img src={movie.Poster} alt={movie.Title} />
                  <div>
                    <h4>{movie.Title}</h4>
                    <p>{movie.Year}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <a href="/" className="home-link" onClick={handleBack}>Home</a>
      </nav>

      {/* Main Content */}
      {!selectedMovie ? (
        <div className="sections-container">
          {/* Render the movie sections dynamically */}
          {Object.keys(preSelectedMovies).map((genre) => (
            <div key={genre} className="section">
              <h2>{genre.charAt(0).toUpperCase() + genre.slice(1)}</h2>
              <div className="movies-row">
                {preSelectedMovies[genre].map((movie) => (
                  <div key={movie.imdbID} className="movie-card" onClick={() => handlePreSelectMovie(movie.imdbID)}>
                    <img src={movie.Poster} alt={movie.Title} />
                    <h4>{movie.Title}</h4>
                  </div>
                ))}
              </div>
              <hr />
            </div>
          ))}
        </div>
      ) : (
        <div className="player-page">
          <div className="content-section">
            <div className="movie-player">
              <iframe
                src={`https://multiembed.mov/?video_id=${selectedMovie.imdbID}`}
                width="100%"
                height="500"
                frameBorder="0"
                allowFullScreen
                title={selectedMovie.Title}
              ></iframe>
              <h2 className="movie-title">{selectedMovie.Title}</h2>
              <div className="movie-description">
                <p><strong>Year:</strong> {selectedMovie.Year}</p>
                <p><strong>Genre:</strong> {selectedMovie.Genre}</p>
                <p><strong>Plot:</strong> {selectedMovie.Plot}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
